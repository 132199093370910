@charset "UTF-8";
/*!
Theme Name: Kobaori
Description: 小林織ネーム様様テンプレート
Version: 1.0
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, object, iframe, pre, code, p, blockquote, form, fieldset, legend, table, th, td, caption, tbody, tfoot, thead, article, aside, figure, footer, header, menu, nav, section, audio, video, canvas, main {
  margin: 0;
  padding: 0; }

article, aside, figure, figcaption, footer, h1, h2, h3, h4, h5, h6, header, menu, nav, section, main {
  display: block; }

html {
  font-size: 62.5%; }

html, body {
  width: 100%;
  height: 100%; }

body {
  color: #333;
  background: #fff;
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ" Meiryo, "Lucida Grande", sans-serif;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%; }

a:link {
  color: #333;
  text-decoration: none; }
a:visited {
  color: #333;
  text-decoration: none; }
a:hover {
  color: #333;
  text-decoration: underline; }
a:active {
  color: #333;
  text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%; }

p {
  line-height: 1.5; }

li {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
  font-family: inherit; }

caption, th, td {
  text-align: left;
  vertical-align: top; }

fieldset {
  border: 0; }

input, textarea, select, label {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: middle; }

label {
  cursor: pointer; }

textarea {
  overflow: auto; }

legend {
  display: none; }

img {
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto; }

object {
  vertical-align: middle;
  outline: 0; }

em {
  font-style: italic; }

strong {
  font-weight: 700; }

small {
  font-size: 100%; }

abbr, acronym {
  border: 0;
  font-variant: normal; }

q:before, q:after {
  content: ""; }

address, caption, cite, code, dfn, var {
  font-weight: 400;
  font-style: normal; }

code, pre {
  font-family: monospace; }

sup {
  vertical-align: text-top; }

sub {
  vertical-align: text-bottom; }

hr {
  display: none; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit; }

#header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  background: url(/wp-content/themes/kobaori/img/common/bg_header_01.png) repeat-x left top; }
  #header .inner {
    overflow: hidden; }
  #header .header-logo {
    float: left;
    background: url(/wp-content/themes/kobaori/img/common/tag_01.png) no-repeat left top;
    padding: 7px 0 0 27px;
    height: 53.5px;
    background-size: 21.5px 53.5px; }
    @media print, screen and (min-width: 768px) {
      #header .header-logo {
        padding: 15px 0 0 54px;
        background-size: 43px 107px;
        height: 107px; } }
    #header .header-logo img {
      max-height: 38.5px; }
      @media print, screen and (min-width: 768px) {
        #header .header-logo img {
          max-height: none; } }

.global-nav, .lang-nav, .sns-nav {
  float: right;
  width: 100%;
  display: none; }
  @media print, screen and (min-width: 768px) {
    .global-nav, .lang-nav, .sns-nav {
      display: block;
      width: auto; } }

#icon__menu {
  display: inline-block;
  padding: 5px 8px;
  float: right;
  border: 1px solid #333;
  border-radius: 5px;
  background: #000;
  color: #fff;
  margin-top: 12px; }
  #icon__menu:hover {
    text-decoration: none; }
  @media print, screen and (min-width: 768px) {
    #icon__menu {
      display: none; } }

#animatedModal {
  padding: 15px; }
  #animatedModal .close-animatedModal {
    text-align: right;
    margin-bottom: 30px; }
    #animatedModal .close-animatedModal img {
      width: 30px;
      height: auto; }
  #animatedModal .modal-content {
    text-align: center; }
  #animatedModal .global-nav, #animatedModal .lang-nav, #animatedModal .sns-nav {
    display: inline-block; }
  #animatedModal .global-nav {
    clear: both; }
    #animatedModal .global-nav ul {
      border: 1px solid #ccc;
      border-radius: 6px; }
  #animatedModal .lang-nav, #animatedModal .sns-nav {
    width: auto;
    margin: 0 0 15px;
    padding: 0; }
  #animatedModal .lang-nav {
    float: none;
    text-align: center; }
  #animatedModal .sns-nav {
    float: left; }

.global-nav {
  clear: right;
  background: #fff; }
  @media print, screen and (min-width: 768px) {
    .global-nav {
      padding-top: 10px;
      background: transparent; }
      .global-nav__home a {
        background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
        background-position: 0px 0px;
        width: 59px;
        height: 34px; }
        .global-nav__home a:hover, .global-nav__home a.is-active {
          background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
          background-position: 0px -39px;
          width: 59px;
          height: 34px; }
      .global-nav__corp a {
        background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
        background-position: 0px -78px;
        width: 163px;
        height: 34px; }
        .global-nav__corp a:hover, .global-nav__corp a.is-active {
          background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
          background-position: 0px -117px;
          width: 163px;
          height: 34px; }
      .global-nav__business a {
        background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
        background-position: 0px -156px;
        width: 86px;
        height: 34px; }
        .global-nav__business a:hover, .global-nav__business a.is-active {
          background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
          background-position: 0px -195px;
          width: 86px;
          height: 34px; }
      .global-nav__news a {
        background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
        background-position: 0px -234px;
        width: 171px;
        height: 34px; }
        .global-nav__news a:hover, .global-nav__news a.is-active {
          background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
          background-position: 0px -273px;
          width: 171px;
          height: 34px; }
      .global-nav__recruit a {
        background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
        background-position: 0px -312px;
        width: 78px;
        height: 34px; }
        .global-nav__recruit a:hover, .global-nav__recruit a.is-active {
          background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
          background-position: 0px -351px;
          width: 78px;
          height: 34px; }
      .global-nav__contact a {
        background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
        background-position: 0px -390px;
        width: 84px;
        height: 34px; }
        .global-nav__contact a:hover, .global-nav__contact a.is-active {
          background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
          background-position: 0px -429px;
          width: 84px;
          height: 34px; } }
  @media print, screen and (min-width: 768px) {
    .global-nav li {
      margin-left: 8px;
      float: left; } }
  .global-nav li:first-child {
    margin: 0; }
  .global-nav li:last-child a {
    border: none; }
  .global-nav li a {
    padding: 10px 0;
    text-align: center;
    display: block;
    border-bottom: 1px solid #ccc; }
    @media print, screen and (min-width: 768px) {
      .global-nav li a {
        padding: 0;
        border: none;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden; } }

.lang-nav {
  float: right;
  margin-right: 19px;
  padding-top: 20px; }
  .lang-nav li {
    padding: 4px 0 10px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    float: left; }
    .lang-nav li a, .lang-nav li span {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      display: block; }
    .lang-nav li:first-child {
      margin-right: 7px;
      background: url("/wp-content/themes/kobaori/img/common/img_slash_01.png") no-repeat right top;
      padding-right: 35px; }
  .lang-nav__en--active span {
    background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
    background-position: 0px -468px;
    width: 53px;
    height: 21px; }
  .lang-nav__en a {
    background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
    background-position: 0px -494px;
    width: 53px;
    height: 21px; }
    .lang-nav__en a:hover {
      background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
      background-position: 0px -468px;
      width: 53px;
      height: 21px; }
  .lang-nav__ja--active span {
    background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
    background-position: 0px -520px;
    width: 61px;
    height: 21px; }
  .lang-nav__ja a {
    background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
    background-position: 0px -546px;
    width: 61px;
    height: 21px; }
    .lang-nav__ja a:hover {
      background-image: url(/wp-content/themes/kobaori/img/common/sprite.png);
      background-position: 0px -520px;
      width: 61px;
      height: 21px; }

.sns-nav {
  margin: 15px 10px 0 0; }
  .sns-nav:hover img {
    opacity: 0.66;
    filter: alpha(opacity=66); }

#footer {
  clear: both;
  background: #000;
  padding: 0 0 15px;
  color: #fff;
  position: relative;
  z-index: 200; }
  @media print, screen and (min-width: 768px) {
    #footer {
      padding: 39px 0 37px; } }
  #footer a {
    color: #fff; }
    #footer a:hover img {
      opacity: 0.66;
      filter: alpha(opacity=66); }
  #footer__bottom {
    clear: both;
    border-top: 1px solid #393939;
    text-align: center;
    padding-top: 11px; }
    #footer__bottom small {
      font-size: 12px;
      font-size: 1.2rem; }

.footer__box {
  width: 100%;
  padding: 15px 10px;
  float: left;
  font-size: 12px;
  font-size: 1.2rem; }
  .footer__box:nth-child(2) {
    margin-top: 10px;
    border-top: 1px solid #393939;
    border-bottom: 1px solid #393939; }
@media print, screen and (min-width: 768px) {
  .footer__box {
    padding: 0 30px 10px;
    width: 33.333333333%;
    margin-bottom: 25px; }
    .footer__box:nth-child(2) {
      margin-top: 0;
      border: none;
      border-left: 1px solid #393939;
      border-right: 1px solid #393939; } }
.footer-logo {
  text-align: center;
  padding-top: 20px;
  margin-bottom: 40px; }
.footer-nav a {
  text-decoration: underline; }
  .footer-nav a:hover {
    text-decoration: none; }
.footer-nav ul {
  overflow: hidden; }
.footer-nav li {
  float: left;
  padding-right: 14px;
  margin-right: 14px;
  margin-bottom: 12px;
  border-right: 1px solid #fff;
  line-height: 1.1; }
  .footer-nav li:last-child {
    padding-right: 0;
    margin-right: 0;
    border: none; }
.footer-language-nav a {
  text-decoration: underline; }
  .footer-language-nav a:hover {
    text-decoration: none; }
.footer-language-nav dt, .footer-language-nav dd {
  float: left;
  margin-right: 12px;
  line-height: 1.1; }
.footer-language-nav dd {
  padding-right: 12px;
  border-right: 1px solid #fff; }
  .footer-language-nav dd:last-child {
    padding-right: 0;
    margin-right: 0;
    border: none; }
.footer-contact {
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem; }
  .footer-contact dt {
    margin-bottom: 9px; }
  .footer-contact dd a {
    display: inline-block;
    text-decoration: underline; }
    .footer-contact dd a:hover {
      text-decoration: none; }
  .footer-contact__tel {
    margin-bottom: 11px; }
    .footer-contact__tel a {
      height: 26px;
      line-height: 26px;
      padding-left: 52px;
      background: url(/wp-content/themes/kobaori/img/common/icon_tel_01.png) no-repeat left center; }
  .footer-contact__form {
    margin-bottom: 24px; }
    .footer-contact__form a {
      height: 26px;
      line-height: 26px;
      padding-left: 37px;
      background: url(/wp-content/themes/kobaori/img/common/icon_form_01.png) no-repeat left center; }
.footer-map {
  padding-top: 7px;
  text-align: center; }
  .footer-map__google-map {
    position: relative;
    padding-bottom: 30%;
    height: 0;
    overflow: hidden;
    margin-bottom: 8px; }
    .footer-map__google-map iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important; }
  .footer-map p {
    text-align: left;
    padding-left: 10px;
    margin-bottom: 10px;
    display: inline-block; }
  .footer-map a {
    border: 1px solid #fff;
    padding: 0 25px;
    height: 29px;
    line-height: 29px;
    display: inline-block; }
    .footer-map a img {
      vertical-align: baseline; }

#main {
  overflow: hidden;
  width: 100%;
  padding-bottom: 50px; }
  #main a:hover img {
    opacity: 0.77;
    filter: alpha(opacity=77); }

.local-nav {
  background: #000;
  overflow: hidden;
  padding-bottom: 10px; }
  @media print, screen and (min-width: 768px) {
    .local-nav {
      padding-bottom: 0; } }
  .local-nav dt, .local-nav dd {
    height: 50px;
    line-height: 50px;
    float: left;
    text-align: center;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center; }
    .local-nav dt a, .local-nav dd a {
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center; }
  .local-nav dt {
    width: 100%; }
    @media print, screen and (min-width: 768px) {
      .local-nav dt {
        width: 17.27273%; } }
  .local-nav dd {
    width: 33.33333333%;
    border-right: 1px solid #535353;
    background-color: #444; }
    .local-nav dd a:hover, .local-nav dd a.is-active {
      background-color: #fff; }

.breadcrumb-nav .inner {
  padding-top: 17px;
  padding-bottom: 17px;
  overflow: hidden; }
.breadcrumb-nav li {
  float: left; }
  .breadcrumb-nav li:after {
    content: '›';
    padding: 0 0.5em; }
  .breadcrumb-nav li:first-child {
    padding-left: 10px; }
  .breadcrumb-nav li:last-child:after {
    content: ''; }
.breadcrumb-nav a {
  text-decoration: underline; }
  .breadcrumb-nav a:hover {
    text-decoration: none; }

.contents__left,
.contents__right,
.contents__one-col {
  padding: 0 10px; }

.contents__left p {
  margin-bottom: 1.5em;
  line-height: 25px; }
  .contents__left p:last-child {
    margin-bottom: 0; }
@media print, screen and (min-width: 768px) {
  .contents__left {
    float: left;
    width: 64.54545%;
    padding-left: 50px; } }

@media print, screen and (min-width: 768px) {
  .contents__right {
    float: right;
    width: 30.90909%;
    padding-right: 50px; } }
.contents__right--business {
  margin-top: 30px; }
  @media print, screen and (min-width: 768px) {
    .contents__right--business {
      margin-top: 0; } }
  .contents__right--business .side-box {
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    margin-top: 48px; }
    .contents__right--business .side-box--ks-luster {
      border-color: #005ead;
      border-bottom: none; }
    .contents__right--business .side-box:first-child {
      margin-top: 0; }
    .contents__right--business .side-box__title {
      color: #3a66a7;
      padding: 6px 11px;
      font-size: 20px;
      font-size: 2rem;
      line-height: 26px;
      font-weight: normal; }
    .contents__right--business .side-box__contents {
      border-top: 2px solid #e5e5e5;
      padding: 11px;
      background: url(/wp-content/themes/kobaori/img/business/common/bg_01.png) repeat;
      line-height: 25px; }
      .contents__right--business .side-box__contents *:last-child {
        margin-bottom: 4px; }
      .contents__right--business .side-box__contents ul, .contents__right--business .side-box__contents dl {
        margin-top: 4px;
        margin-bottom: 1.5em; }
      .contents__right--business .side-box__contents li {
        list-style-type: disc;
        list-style-position: inside;
        margin-left: 0.5em; }
      .contents__right--business .side-box__contents dd {
        margin-bottom: 1em; }
      .contents__right--business .side-box__contents--ks-luster {
        padding: 0;
        border-top: 0;
        background: transparent; }
        .contents__right--business .side-box__contents--ks-luster ul {
          margin-bottom: 0;
          padding: 0 0.5em; }
          .contents__right--business .side-box__contents--ks-luster ul li {
            text-indent: -1em;
            margin-left: 1em; }
        .contents__right--business .side-box__contents--ks-luster div {
          background: #005ead;
          padding: 15px;
          color: #fff;
          font-size: 12px;
          font-size: 1.2rem; }
          .contents__right--business .side-box__contents--ks-luster div p:first-child {
            margin-bottom: 1em; }
.contents__right--news h3 {
  text-align: center;
  margin-bottom: 10px; }
.contents__right--news ul {
  font-size: 12px;
  font-size: 1.2rem;
  background: #d8d9db;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px; }
  .contents__right--news ul li {
    background: url(/wp-content/themes/kobaori/img/common/border_01.png) repeat-x left bottom;
    padding-bottom: 6px;
    margin-bottom: 5px; }
    .contents__right--news ul li:last-child {
      background: none;
      padding: 0;
      margin: 0; }

.contents__one-col {
  clear: both;
  padding-top: 50px; }
  @media print, screen and (min-width: 768px) {
    .contents__one-col {
      padding-left: 50px;
      padding-right: 50px; } }

.main__visual {
  height: 400px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center; }
  .main__visual img {
    max-height: 250px; }
  @media print, screen and (min-width: 768px) {
    .main__visual {
      height: 822px; }
      .main__visual img {
        max-height: none; } }
  .main__visual--sub {
    height: 300px;
    padding-top: 175px; }
    @media print, screen and (min-width: 768px) {
      .main__visual--sub {
        height: 400px;
        padding-top: 210px; } }
    .main__visual--sub img {
      max-height: 45px;
      width: auto; }
      @media print, screen and (min-width: 768px) {
        .main__visual--sub img {
          max-height: none; } }
.main__lead {
  padding: 38px 0 0;
  text-align: center; }
  .main__lead img {
    max-width: 90%; }
    @media print, screen and (min-width: 768px) {
      .main__lead img {
        max-width: 100%; } }
.main__contents {
  padding-top: 30px; }
  @media print, screen and (min-width: 768px) {
    .main__contents {
      padding-top: 60px;
      min-height: 600px; } }

.home #main {
  padding-bottom: 0; }
.home #tag .sprite {
  width: 252px;
  height: 525px;
  margin: 0 auto;
  background: url(/wp-content/themes/kobaori/img/home/tag_sprite.png) no-repeat -5px -5px; }
  .home #tag .sprite.is-active {
    animation: play 0.7s steps(7) infinite;
    animation-iteration-count: 1; }
@keyframes play {
  0% {
    background-position: -5px -5px; }
  100% {
    background-position: -5px -3750px; } }
.home .main {
  padding-top: 400px;
  background: #fff; }
  .home .main > * {
    position: relative;
    z-index: 100; }
  @media print, screen and (min-width: 768px) {
    .home .main {
      padding-top: 822px; } }
  .home .main__visual {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    height: 400px !important; }
    @media print, screen and (min-width: 768px) {
      .home .main__visual {
        height: 822px !important; } }
    .home .main__visual p {
      color: #fff;
      position: absolute;
      bottom: 35px;
      left: 0;
      width: 100%;
      text-shadow: 0 0 4px #000; }
      @media print, screen and (min-width: 768px) {
        .home .main__visual p {
          bottom: 95px; } }
  .home .main__news {
    background: #000;
    color: #fff;
    font-size: 12px;
    font-size: 1.2rem; }
    .home .main__news a {
      color: #fff;
      text-decoration: underline; }
      .home .main__news a:hover {
        text-decoration: none; }
    .home .main__news dl {
      padding-top: 15px;
      padding-bottom: 15px; }
    @media print, screen and (min-width: 768px) {
      .home .main__news dt {
        float: left; }
      .home .main__news dd {
        margin-left: 175px; } }
  .home .main__bottom {
    background: #fff; }
    .home .main__bottom ul {
      padding: 40px 0 20px;
      overflow: hidden; }
      @media print, screen and (min-width: 768px) {
        .home .main__bottom ul {
          padding: 70px 0; } }
    .home .main__bottom li {
      float: left;
      width: 100%;
      overflow: hidden;
      margin-bottom: 20px; }
      @media print, screen and (min-width: 768px) {
        .home .main__bottom li {
          padding: 0 10px;
          margin-bottom: 0;
          width: 25%;
          border-right: 1px solid #d4d4d4; }
          .home .main__bottom li:last-child {
            border-right: none; } }
    .home .main__bottom h3, .home .main__bottom img {
      float: left; }
    .home .main__bottom h3 {
      font-weight: normal;
      padding-left: 10px;
      width: 55%; }
      @media print, screen and (min-width: 768px) {
        .home .main__bottom h3 {
          padding: 4px 0 4px 10px; } }
      .home .main__bottom h3 .en {
        font-size: 17px;
        font-size: 1.7rem;
        word-wrap: break-word;
        overflow-wrap: break-word; }
      .home .main__bottom h3 .ja {
        color: #888;
        font-size: 12px;
        font-size: 1.2rem; }
    .home .main__bottom img {
      max-width: 45%;
      height: auto; }
.home .sec-business {
  background: #fff; }
  .home .sec-business__title {
    text-align: center; }
    .home .sec-business__title .inner {
      padding-top: 67px;
      padding-bottom: 36px;
      background: url(/wp-content/themes/kobaori/img/home/bg_01.png) no-repeat right top; }
  .home .sec-business__contents {
    padding-top: 1px;
    background: url(/wp-content/themes/kobaori/img/home/bg_02.png) repeat; }
    .home .sec-business__contents .inner {
      overflow: hidden;
      padding-right: 35px;
      padding-left: 35px; }
    .home .sec-business__contents li {
      background: #fff; }

.page-corp .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/main_visual_01.jpg); }
@media print, screen and (min-width: 768px) {
  .page-corp #main .local-nav dt {
    width: 30%; }
  .page-corp #main .local-nav dd {
    width: 11.66667%; } }
.page-corp #main .local-nav__index a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_01_01.png); }
.page-corp #main .local-nav__message a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_02_01.png); }
  .page-corp #main .local-nav__message a:hover, .page-corp #main .local-nav__message a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_02_02.png); }
.page-corp #main .local-nav__concept a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_03_01.png); }
  .page-corp #main .local-nav__concept a:hover, .page-corp #main .local-nav__concept a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_03_02.png); }
.page-corp #main .local-nav__profile a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_04_01.png); }
  .page-corp #main .local-nav__profile a:hover, .page-corp #main .local-nav__profile a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_04_02.png); }
.page-corp #main .local-nav__network a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_05_01.png); }
  .page-corp #main .local-nav__network a:hover, .page-corp #main .local-nav__network a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_05_02.png); }
.page-corp #main .local-nav__history a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_06_01.png); }
  .page-corp #main .local-nav__history a:hover, .page-corp #main .local-nav__history a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_06_02.png); }
.page-corp #main .local-nav__crs a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_07_01.png); }
  .page-corp #main .local-nav__crs a:hover, .page-corp #main .local-nav__crs a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_07_02.png); }
.page-corp #main .local-nav--en .local-nav__index a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_01_01.png); }
.page-corp #main .local-nav--en .local-nav__message a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_02_01.png); }
  .page-corp #main .local-nav--en .local-nav__message a:hover, .page-corp #main .local-nav--en .local-nav__message a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_02_02.png); }
.page-corp #main .local-nav--en .local-nav__concept a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_03_01.png); }
  .page-corp #main .local-nav--en .local-nav__concept a:hover, .page-corp #main .local-nav--en .local-nav__concept a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_03_02.png); }
.page-corp #main .local-nav--en .local-nav__profile a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_04_01.png); }
  .page-corp #main .local-nav--en .local-nav__profile a:hover, .page-corp #main .local-nav--en .local-nav__profile a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_04_02.png); }
.page-corp #main .local-nav--en .local-nav__network a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_05_01.png); }
  .page-corp #main .local-nav--en .local-nav__network a:hover, .page-corp #main .local-nav--en .local-nav__network a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_05_02.png); }
.page-corp #main .local-nav--en .local-nav__history a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_06_01.png); }
  .page-corp #main .local-nav--en .local-nav__history a:hover, .page-corp #main .local-nav--en .local-nav__history a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_06_02.png); }
.page-corp #main .local-nav--en .local-nav__crs a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_07_01.png); }
  .page-corp #main .local-nav--en .local-nav__crs a:hover, .page-corp #main .local-nav--en .local-nav__crs a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_07_02.png); }
.page-corp .inner--corp {
  max-width: 1000px; }

@media print, screen and (min-width: 768px) {
  .page-japan #main .local-nav dt, .page-world #main .local-nav dt {
    width: 30%; }
  .page-japan #main .local-nav dd, .page-world #main .local-nav dd {
    width: 11.66667%; } }
.page-japan #main .local-nav__index a, .page-world #main .local-nav__index a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_01_01.png); }
.page-japan #main .local-nav__message a, .page-world #main .local-nav__message a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_02_01.png); }
  .page-japan #main .local-nav__message a:hover, .page-japan #main .local-nav__message a.is-active, .page-world #main .local-nav__message a:hover, .page-world #main .local-nav__message a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_02_02.png); }
.page-japan #main .local-nav__concept a, .page-world #main .local-nav__concept a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_03_01.png); }
  .page-japan #main .local-nav__concept a:hover, .page-japan #main .local-nav__concept a.is-active, .page-world #main .local-nav__concept a:hover, .page-world #main .local-nav__concept a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_03_02.png); }
.page-japan #main .local-nav__profile a, .page-world #main .local-nav__profile a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_04_01.png); }
  .page-japan #main .local-nav__profile a:hover, .page-japan #main .local-nav__profile a.is-active, .page-world #main .local-nav__profile a:hover, .page-world #main .local-nav__profile a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_04_02.png); }
.page-japan #main .local-nav__network a, .page-world #main .local-nav__network a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_05_01.png); }
  .page-japan #main .local-nav__network a:hover, .page-japan #main .local-nav__network a.is-active, .page-world #main .local-nav__network a:hover, .page-world #main .local-nav__network a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_05_02.png); }
.page-japan #main .local-nav__history a, .page-world #main .local-nav__history a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_06_01.png); }
  .page-japan #main .local-nav__history a:hover, .page-japan #main .local-nav__history a.is-active, .page-world #main .local-nav__history a:hover, .page-world #main .local-nav__history a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_06_02.png); }
.page-japan #main .local-nav__crs a, .page-world #main .local-nav__crs a {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_07_01.png); }
  .page-japan #main .local-nav__crs a:hover, .page-japan #main .local-nav__crs a.is-active, .page-world #main .local-nav__crs a:hover, .page-world #main .local-nav__crs a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/corp/common/local_nav_07_02.png); }
.page-japan #main .local-nav--en .local-nav__index a, .page-world #main .local-nav--en .local-nav__index a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_01_01.png); }
.page-japan #main .local-nav--en .local-nav__message a, .page-world #main .local-nav--en .local-nav__message a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_02_01.png); }
  .page-japan #main .local-nav--en .local-nav__message a:hover, .page-japan #main .local-nav--en .local-nav__message a.is-active, .page-world #main .local-nav--en .local-nav__message a:hover, .page-world #main .local-nav--en .local-nav__message a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_02_02.png); }
.page-japan #main .local-nav--en .local-nav__concept a, .page-world #main .local-nav--en .local-nav__concept a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_03_01.png); }
  .page-japan #main .local-nav--en .local-nav__concept a:hover, .page-japan #main .local-nav--en .local-nav__concept a.is-active, .page-world #main .local-nav--en .local-nav__concept a:hover, .page-world #main .local-nav--en .local-nav__concept a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_03_02.png); }
.page-japan #main .local-nav--en .local-nav__profile a, .page-world #main .local-nav--en .local-nav__profile a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_04_01.png); }
  .page-japan #main .local-nav--en .local-nav__profile a:hover, .page-japan #main .local-nav--en .local-nav__profile a.is-active, .page-world #main .local-nav--en .local-nav__profile a:hover, .page-world #main .local-nav--en .local-nav__profile a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_04_02.png); }
.page-japan #main .local-nav--en .local-nav__network a, .page-world #main .local-nav--en .local-nav__network a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_05_01.png); }
  .page-japan #main .local-nav--en .local-nav__network a:hover, .page-japan #main .local-nav--en .local-nav__network a.is-active, .page-world #main .local-nav--en .local-nav__network a:hover, .page-world #main .local-nav--en .local-nav__network a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_05_02.png); }
.page-japan #main .local-nav--en .local-nav__history a, .page-world #main .local-nav--en .local-nav__history a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_06_01.png); }
  .page-japan #main .local-nav--en .local-nav__history a:hover, .page-japan #main .local-nav--en .local-nav__history a.is-active, .page-world #main .local-nav--en .local-nav__history a:hover, .page-world #main .local-nav--en .local-nav__history a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_06_02.png); }
.page-japan #main .local-nav--en .local-nav__crs a, .page-world #main .local-nav--en .local-nav__crs a {
  background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_07_01.png); }
  .page-japan #main .local-nav--en .local-nav__crs a:hover, .page-japan #main .local-nav--en .local-nav__crs a.is-active, .page-world #main .local-nav--en .local-nav__crs a:hover, .page-world #main .local-nav--en .local-nav__crs a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/corp/common/local_nav_07_02.png); }

.page-corp.page-concept .main__contents p {
  font-size: 14px;
  font-size: 1.4rem;
  margin-bottom: 30px; }
.page-corp.page-concept .main__contents h3 {
  margin-bottom: 30px; }
.page-corp.page-concept .main__contents .text--attr {
  font-size: 12px;
  font-size: 1.2rem;
  margin-bottom: 60px; }
.page-corp.page-concept .main__contents .list-wrapper li {
  float: left;
  margin: 0;
  margin-bottom: 8px;
  border: 1px solid #2553A5; }
  @media print, screen and (min-width: 768px) {
    .page-corp.page-concept .main__contents .list-wrapper li {
      float: left;
      width: 49.5%;
      margin-right: 1%; } }
  .page-corp.page-concept .main__contents .list-wrapper li:last-child {
    margin-right: 0; }
  .page-corp.page-concept .main__contents .list-wrapper li h4 {
    float: left; }
  .page-corp.page-concept .main__contents .list-wrapper li dl {
    margin-left: 76px;
    padding: 15px;
    border-left-width: 1px;
    border-left-style: dotted;
    border-left-color: #999;
    min-height: 250px; }
    .page-corp.page-concept .main__contents .list-wrapper li dl dt {
      text-align: center;
      margin-bottom: 15px; }
    .page-corp.page-concept .main__contents .list-wrapper li dl dd {
      font-size: 14px;
      font-size: 1.4rem; }
.page-corp.page-concept .main__contents--en .list-wrapper li dl {
  margin-left: 0;
  padding: 15px;
  min-height: 280px; }

.page-corp.page-history .main__contents--en .his_in_box {
  padding-left: 55px; }

.page-corp.page-csr .con_txt {
  margin-left: 85px;
  float: none; }
.page-corp.page-csr .main__contents--en h3 {
  text-align: center;
  border-bottom: 1px dotted #999;
  padding-bottom: 10px;
  margin-bottom: 10px; }
.page-corp.page-csr .main__contents--en .crs_b_txt {
  margin-left: 0;
  border-left: 0; }

.page-business #main .local-nav .page-corp .local-nav dt {
  width: 30%; }
@media print, screen and (min-width: 768px) {
  .page-business #main .local-nav dd {
    width: 9.19192%; } }
.page-business #main .local-nav__index a {
  background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_01_01.png); }
.page-business #main .local-nav__fashion a {
  background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_02_01.png); }
  .page-business #main .local-nav__fashion a:hover, .page-business #main .local-nav__fashion a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_02_02.png); }
.page-business #main .local-nav__package a {
  background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_03_01.png); }
  .page-business #main .local-nav__package a:hover, .page-business #main .local-nav__package a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_03_02.png); }
.page-business #main .local-nav__brand a {
  background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_04_01.png); }
  .page-business #main .local-nav__brand a:hover, .page-business #main .local-nav__brand a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_04_02.png); }
.page-business #main .local-nav__function a {
  background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_05_01.png); }
  .page-business #main .local-nav__function a:hover, .page-business #main .local-nav__function a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_05_02.png); }
.page-business #main .local-nav__security a {
  background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_06_01.png); }
  .page-business #main .local-nav__security a:hover, .page-business #main .local-nav__security a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_06_02.png); }
.page-business #main .local-nav__only a {
  background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_07_01.png); }
  .page-business #main .local-nav__only a:hover, .page-business #main .local-nav__only a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_07_02.png); }
.page-business #main .local-nav__rfid a {
  background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_08_01.png); }
  .page-business #main .local-nav__rfid a:hover, .page-business #main .local-nav__rfid a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_08_02.png); }
.page-business #main .local-nav__rd a {
  background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_09_01.png); }
  .page-business #main .local-nav__rd a:hover, .page-business #main .local-nav__rd a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_09_02.png); }
.page-business #main .local-nav__ks-luster a {
  background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_10_01.png); }
  .page-business #main .local-nav__ks-luster a:hover, .page-business #main .local-nav__ks-luster a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/business/common/local_nav_10_02.png); }
.page-business #main .local-nav--en .local-nav__index a {
  background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_01_01.png); }
.page-business #main .local-nav--en .local-nav__fashion a {
  background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_02_01.png); }
  .page-business #main .local-nav--en .local-nav__fashion a:hover, .page-business #main .local-nav--en .local-nav__fashion a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_02_02.png); }
.page-business #main .local-nav--en .local-nav__package a {
  background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_03_01.png); }
  .page-business #main .local-nav--en .local-nav__package a:hover, .page-business #main .local-nav--en .local-nav__package a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_03_02.png); }
.page-business #main .local-nav--en .local-nav__brand a {
  background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_04_01.png); }
  .page-business #main .local-nav--en .local-nav__brand a:hover, .page-business #main .local-nav--en .local-nav__brand a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_04_02.png); }
.page-business #main .local-nav--en .local-nav__function a {
  background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_05_01.png); }
  .page-business #main .local-nav--en .local-nav__function a:hover, .page-business #main .local-nav--en .local-nav__function a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_05_02.png); }
.page-business #main .local-nav--en .local-nav__security a {
  background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_06_01.png); }
  .page-business #main .local-nav--en .local-nav__security a:hover, .page-business #main .local-nav--en .local-nav__security a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_06_02.png); }
.page-business #main .local-nav--en .local-nav__only a {
  background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_07_01.png); }
  .page-business #main .local-nav--en .local-nav__only a:hover, .page-business #main .local-nav--en .local-nav__only a.is-active {
    background-image: url(/wp-content/themes/kobaori/img/en/business/common/local_nav_07_02.png); }

.page-business .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/business/index/main_visual_01.png); }
.page-business .main__contents {
  overflow: hidden; }
.page-business .main__list--index li {
  float: left;
  text-align: center;
  width: 50%;
  padding: 0 10px; }
  @media print, screen and (min-width: 768px) {
    .page-business .main__list--index li {
      padding: 0 20px; } }
  .page-business .main__list--index li img {
    margin-bottom: 16px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75); }
  .page-business .main__list--index li span {
    display: inline-block;
    margin-bottom: 40px; }

.page-brand .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/business/brand/main_visual_01.png); }
.page-brand .main__contents .contents__left ol {
  margin-bottom: 40px; }
  @media print, screen and (min-width: 768px) {
    .page-brand .main__contents .contents__left ol {
      margin-bottom: 85px; } }
  .page-brand .main__contents .contents__left ol li {
    overflow: hidden; }
    .page-brand .main__contents .contents__left ol li img {
      width: 49.25373%;
      max-width: 330px;
      float: left; }
    .page-brand .main__contents .contents__left ol li p, .page-brand .main__contents .contents__left ol li ul {
      margin-left: 50.74627%; }
      .page-brand .main__contents .contents__left ol li p:last-child, .page-brand .main__contents .contents__left ol li ul:last-child {
        margin-bottom: 1em !important; }

.page-fashion .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/business/fashion/main_visual_01.png); }
.page-fashion .main .contents__one-col {
  padding: 30px 10px;
  clear: both; }
  @media print, screen and (min-width: 768px) {
    .page-fashion .main .contents__one-col {
      padding: 68px 50px 0 !important; } }
  .page-fashion .main .contents__one-col li {
    float: left;
    width: 100%;
    padding: 20px;
    border: 3px solid #454db4;
    margin-bottom: 45px;
    position: relative; }
    @media print, screen and (min-width: 768px) {
      .page-fashion .main .contents__one-col li {
        width: 48.8%;
        min-height: 245px; }
        .page-fashion .main .contents__one-col li:nth-child(2n) {
          float: right; } }
    .page-fashion .main .contents__one-col li h3 {
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      text-align: center; }
      .page-fashion .main .contents__one-col li h3 img {
        padding: 0 13px;
        background: #fff; }
    .page-fashion .main .contents__one-col li div {
      float: left; }
    .page-fashion .main .contents__one-col li p {
      margin-left: 170px;
      line-height: 1.7; }

.page-function .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/business/function-product/main_visual_01.png); }
  @media print, screen and (min-width: 768px) {
    .page-function .main__visual--sub {
      padding-top: 107px; } }
.page-function .main__contents {
  /*------------------------------------*\
  		summary
  \*------------------------------------*/
  /*------------------------------------*\
  		.main__contents-sec [common]
  \*------------------------------------*/
  /*------------------------------------*\
  		main__contents-sec01
  \*------------------------------------*/
  /*------------------------------------*\
  		main__contents-sec02
  \*------------------------------------*/
  /*------------------------------------*\
  		main__contents-sec03
  \*------------------------------------*/ }
  .page-function .main__contents-summary {
    overflow: hidden; }
    .page-function .main__contents-summary .text {
      line-height: 1.78; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-summary .text {
          float: left;
          width: 65%;
          padding-right: 3%; } }
      .page-function .main__contents-summary .text strong {
        font-size: 18px;
        font-size: 1.8rem; }
    .page-function .main__contents-summary .page-anc {
      background: url(/wp-content/themes/kobaori/img/business/function-product/bg_page_anc.png) left top repeat;
      box-sizing: border-box;
      padding: 6px;
      margin-top: 20px; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-summary .page-anc {
          float: right;
          width: 32%;
          margin-top: 0; } }
      .page-function .main__contents-summary .page-anc li {
        border: 1px solid #cdcdcd;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
        text-align: center;
        margin-top: 7px; }
        .page-function .main__contents-summary .page-anc li:first-child {
          margin-top: 0; }
        .page-function .main__contents-summary .page-anc li a {
          display: block;
          width: 100%;
          box-sizing: border-box;
          background: #fff;
          padding: 12px 12.5%; }
  .page-function .main__contents-sec {
    margin-top: 40px; }
    .page-function .main__contents-sec .sec-title {
      padding: 20px 0;
      text-align: center; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec .sec-title {
          padding: 0;
          min-height: 356px;
          line-height: 356px; } }
      .page-function .main__contents-sec .sec-title__img {
        width: 80%; }
        @media print, screen and (min-width: 768px) {
          .page-function .main__contents-sec .sec-title__img {
            width: auto; } }
    .page-function .main__contents-sec .sec-note-title {
      font-size: 24px;
      font-size: 2.4rem;
      text-align: center;
      margin-top: 25px; }
      .page-function .main__contents-sec .sec-note-title:after {
        display: block;
        content: "";
        width: 86px;
        border-top: 1px solid #000;
        margin: 0 auto; }
    .page-function .main__contents-sec .sec-note-text {
      margin-top: 25px; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents-sec .sec-block {
        margin-top: 95px; } }
    .page-function .main__contents-sec .sec-block-title {
      color: #fff;
      font-size: 18px;
      font-size: 1.8rem;
      text-align: center;
      padding: 20px 0;
      margin-top: 40px; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec .sec-block-title {
          font-size: 26px;
          font-size: 2.6rem; } }
    .page-function .main__contents-sec .sec-block-text {
      margin-top: 25px; }
    .page-function .main__contents-sec .sec-block-tag {
      color: #fff;
      text-align: center;
      padding: 10px 15px;
      margin-top: 25px; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec .sec-block-tag {
          display: inline-block;
          font-size: 18px;
          font-size: 1.8rem;
          margin-top: 62px; } }
  .page-function .main__contents-sec01 .sec01-title {
    background: url(/wp-content/themes/kobaori/img/business/function-product/bg_sec_01.jpg) center top no-repeat;
    background-size: cover; }
  .page-function .main__contents-sec01 .sec-block-title {
    background: #574674; }
  .page-function .main__contents-sec01 .sec-block-tag {
    background: #574674; }
  .page-function .main__contents-sec01 .sec-block-first .sec-block-desc {
    overflow: hidden;
    margin-top: 25px;
    text-align: center; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents-sec01 .sec-block-first .sec-block-desc {
        text-align: left; }
        .page-function .main__contents-sec01 .sec-block-first .sec-block-desc__img {
          float: left; } }
  .page-function .main__contents-sec01 .sec-block-first .sec-block-desc__summary {
    margin-top: 25px; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents-sec01 .sec-block-first .sec-block-desc__summary {
        float: right;
        width: 64.5%;
        overflow: hidden;
        margin-top: 0; } }
    .page-function .main__contents-sec01 .sec-block-first .sec-block-desc__summary li {
      text-align: center;
      margin-top: 10px; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec01 .sec-block-first .sec-block-desc__summary li {
          float: left;
          width: 33.3333333%;
          margin-top: 0;
          margin-bottom: 33px; } }
      .page-function .main__contents-sec01 .sec-block-first .sec-block-desc__summary li:first-child {
        margin-top: 0; }
  .page-function .main__contents-sec01 .sec-block-second .sec-block-desc {
    margin-top: 25px; }
  .page-function .main__contents-sec01 .sec-block-second .sec-block-desc__summary {
    margin-top: 25px;
    overflow: hidden; }
    .page-function .main__contents-sec01 .sec-block-second .sec-block-desc__summary li {
      text-align: center;
      margin-top: 15px; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec01 .sec-block-second .sec-block-desc__summary li {
          float: left;
          width: 32%;
          margin-top: 0;
          margin-left: 2%; } }
      .page-function .main__contents-sec01 .sec-block-second .sec-block-desc__summary li:first-child {
        margin-top: 0;
        margin-left: 0; }
      .page-function .main__contents-sec01 .sec-block-second .sec-block-desc__summary li iframe {
        width: 100%;
        height: 250px; }
  .page-function .main__contents-sec01 .sec-block-desc__text {
    margin-top: 25px; }
  .page-function .main__contents-sec02 .sec02-title {
    background: url(/wp-content/themes/kobaori/img/business/function-product/bg_sec_02.jpg) center top no-repeat;
    background-size: cover; }
  .page-function .main__contents-sec02 .sec-block-title {
    background: #005ead; }
  .page-function .main__contents-sec02 .sec-block-desc {
    margin-top: 25px; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents-sec02 .sec-block-desc {
        overflow: hidden; } }
    .page-function .main__contents-sec02 .sec-block-desc li {
      margin-top: 25px; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec02 .sec-block-desc li {
          float: left;
          width: 25%; } }
      .page-function .main__contents-sec02 .sec-block-desc li:first-child {
        margin-top: none; }
      .page-function .main__contents-sec02 .sec-block-desc li img {
        display: block;
        margin: 0 auto; }
        @media print, screen and (min-width: 768px) {
          .page-function .main__contents-sec02 .sec-block-desc li img {
            margin: 0; } }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec02 .sec-block-desc li dl {
          width: 90%; } }
      .page-function .main__contents-sec02 .sec-block-desc li dt, .page-function .main__contents-sec02 .sec-block-desc li dd {
        margin-top: 15px; }
      .page-function .main__contents-sec02 .sec-block-desc li dt {
        color: #005ead;
        font-size: 18px;
        font-size: 1.8rem; }
  .page-function .main__contents-sec02 .sec-block-att {
    margin-top: 15px; }
  .page-function .main__contents-sec03 .sec03-title {
    background: url(/wp-content/themes/kobaori/img/business/function-product/bg_sec_03.jpg) center top no-repeat;
    background-size: cover; }
  @media print, screen and (min-width: 768px) {
    .page-function .main__contents-sec03 .sec-note-text {
      text-align: center; } }
  .page-function .main__contents-sec03 .sec-block-title {
    background: #00aa14;
    padding: 0; }
    .page-function .main__contents-sec03 .sec-block-title span {
      display: block;
      background: #005b0b;
      padding: 1%; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec03 .sec-block-title span {
          display: inline-block;
          background: #005b0b;
          padding: 20px 10px;
          margin-right: 10px; } }
  .page-function .main__contents-sec03 .sec-block-desc-title {
    font-size: 20px;
    font-size: 2rem;
    border-bottom: 1px solid #11b024;
    margin-top: 15px; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents-sec03 .sec-block-desc-title {
        margin-top: 42px; } }
  .page-function .main__contents-sec03 .sec-block-tag {
    background: #00aa14; }
  .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .sec-block-desc-title {
    display: inline-block; }
  @media print, screen and (min-width: 768px) {
    .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .sec-block-desc-list {
      margin-top: 40px; } }
  .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .sec-block-desc-list li {
    margin-top: 10px; }
  .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .img-list {
    overflow: hidden;
    margin-top: 15px; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .img-list {
        margin-top: 36px; } }
    .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .img-list li {
      margin-top: 25px;
      text-align: center; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .img-list li {
          float: left;
          width: 22.2%;
          margin-left: 1.8%;
          margin-top: 0;
          text-align: left; } }
      .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .img-list li:first-child {
        margin-top: 0; }
        @media print, screen and (min-width: 768px) {
          .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .img-list li:first-child {
            width: auto;
            border-right: 1px solid #000;
            padding-right: 10px;
            margin-left: 0; } }
    .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .img-list__title {
      margin-bottom: 5px; }
    .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-first .img-list__caption {
      margin-top: 5px; }
  .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-second-inner {
    margin-top: 25px;
    overflow: hidden; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-second-inner p {
        float: left;
        width: 53%; } }
    .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-second-inner table {
      border-top: 1px solid #6f6f6f;
      border-left: 1px solid #6f6f6f;
      box-sizing: border-box;
      margin-top: 25px; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-second-inner table {
          float: right;
          width: 45%;
          margin-top: 0; } }
      .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-second-inner table th, .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-second-inner table td {
        border-right: 1px solid #6f6f6f;
        border-bottom: 1px solid #6f6f6f;
        box-sizing: border-box;
        padding: 1% 3%; }
      .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-second-inner table th {
        background: #dcdcdc;
        text-align: center;
        vertical-align: middle; }
      .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-second-inner table .head {
        text-align: center; }
  .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-second .att {
    font-size: 12px;
    font-size: 1.2rem;
    margin-top: 5px;
    text-align: right; }
  .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-third .img-list {
    overflow: hidden;
    margin-top: 25px; }
    .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-third .img-list li {
      margin-top: 15px; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-third .img-list li {
          float: left;
          margin-top: 0;
          margin-left: 3%; }
          .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-third .img-list li:first-child {
            margin-left: 0; } }
  .page-function .main__contents-sec03 .sec-block-first .sec-block-desc-third .att {
    color: #00aa14; }
  .page-function .main__contents-sec03 .sec-block-second .sec-block-desc-list li {
    margin-top: 15px;
    overflow: hidden; }
  .page-function .main__contents-sec03 .sec-block-second .sec-block-desc-list .img-area {
    border: 1px solid #00aa14;
    box-sizing: border-box;
    text-align: center; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents-sec03 .sec-block-second .sec-block-desc-list .img-area {
        float: left;
        display: inline-block; } }
    .page-function .main__contents-sec03 .sec-block-second .sec-block-desc-list .img-area img {
      width: auto;
      height: 100%; }
  @media print, screen and (min-width: 768px) {
    .page-function .main__contents-sec03 .sec-block-second .sec-block-desc-list .text-area {
      float: left;
      width: 62%;
      margin-left: 2%; } }
  .page-function .main__contents-sec03 .sec-block-second .sec-block-desc-list .text-area__title {
    color: #00aa14;
    font-size: 18px;
    font-size: 1.8rem;
    margin-top: 10px; }
  .page-function .main__contents-sec03 .sec-block-second .att {
    border: 1px solid #00aa14;
    margin-top: 25px;
    overflow: hidden; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents-sec03 .sec-block-second .att {
        display: table;
        width: 100%; } }
    .page-function .main__contents-sec03 .sec-block-second .att-title {
      color: #fff;
      background: #00aa14;
      text-align: center;
      padding: 30px 0;
      box-sizing: border-box; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec03 .sec-block-second .att-title {
          display: table-cell;
          height: 92px;
          vertical-align: middle;
          padding: 0 14px; } }
    .page-function .main__contents-sec03 .sec-block-second .att-text {
      padding: 30px 3%;
      box-sizing: border-box; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec03 .sec-block-second .att-text {
          display: table-cell;
          height: 92px;
          vertical-align: middle;
          padding-left: 26px;
          background: url(/wp-content/themes/kobaori/img/business/function-product/icon_arrow_sec_03.png) left center no-repeat; } }
  .page-function .main__contents-sec03 .sec-block-second .other-text {
    margin-top: 25px; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents-sec03 .sec-block-second .other-text {
        margin-top: 72px; } }
    .page-function .main__contents-sec03 .sec-block-second .other-text__title {
      color: #00aa14;
      font-size: 24px;
      font-size: 2.4rem;
      border-bottom: 1px solid #000; }
      @media print, screen and (min-width: 768px) {
        .page-function .main__contents-sec03 .sec-block-second .other-text__title {
          display: inline-block; } }
    .page-function .main__contents-sec03 .sec-block-second .other-text__list {
      margin-top: 15px; }
      .page-function .main__contents-sec03 .sec-block-second .other-text__list li {
        margin-top: 10px; }
        .page-function .main__contents-sec03 .sec-block-second .other-text__list li:first-child {
          margin-top: 0; }
  .page-function .main__contents .youtube-wrapper {
    overflow: hidden;
    padding-top: 20px; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents .youtube-wrapper .youtube {
        float: left; } }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents .youtube-wrapper p {
        margin-left: 330px; } }
  .page-function .main__contents .youtube iframe {
    max-width: 100%; }
    @media print, screen and (min-width: 768px) {
      .page-function .main__contents .youtube iframe {
        width: 100%;
        max-width: 320px; } }
  .page-function .main__contents .btn-two {
    width: 100%;
    margin-top: 40px;
    padding: 10px; }
    .page-function .main__contents .btn-two.blue {
      background: url(/wp-content/themes/kobaori/img/business/function-product/bg_sec_02_02.png); }
    .page-function .main__contents .btn-two.glay {
      background: url(/wp-content/themes/kobaori/img/business/function-product/bg_sec_01_02.png); }
    .page-function .main__contents .btn-two.green {
      background: url(/wp-content/themes/kobaori/img/business/function-product/bg_sec_03_02.png); }
    .page-function .main__contents .btn-two ul {
      text-align: center; }
    .page-function .main__contents .btn-two li {
      display: inline-block;
      margin-right: 23px; }
      .page-function .main__contents .btn-two li:last-child {
        margin-right: 0; }
    .page-function .main__contents .btn-two a {
      display: block;
      width: 280px;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      background: #fff;
      box-shadow: rgba(120, 120, 120, 0.65098) 0 0 6px 3px; }

.page-ks-luster .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/business/ks-luster/main_visual_01.png); }
.page-ks-luster .main .contents__ks-luster h3 {
  color: #005ead;
  padding-bottom: 7px;
  margin-bottom: 5px;
  margin-top: 30px;
  border-bottom: 2px solid #88b4d9;
  font-weight: normal;
  font-size: 18px;
  font-size: 1.8rem; }
  .page-ks-luster .main .contents__ks-luster h3:first-child {
    margin-top: 0; }
.page-ks-luster .main .contents__ks-luster li, .page-ks-luster .main .contents__ks-luster p {
  text-indent: -1em;
  margin-left: 1em;
  margin-bottom: 0; }
.page-ks-luster .main .contents__ks-luster ul li {
  line-height: 24px;
  font-weight: bold; }
  .page-ks-luster .main .contents__ks-luster ul li:before {
    content: '・'; }
  .page-ks-luster .main .contents__ks-luster ul li span {
    font-weight: normal; }
    .page-ks-luster .main .contents__ks-luster ul li span.is-attr {
      margin-left: -1em;
      font-size: 12px;
      font-size: 1.2rem; }
@media print, screen and (min-width: 768px) {
  .page-ks-luster .main .contents__ks-luster {
    padding-left: 10px; } }

.page-only .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/business/only/main_visual_01.png); }
.page-only .main .contents__left p {
  text-shadow: 1px 1px 1px #fff;
  padding-bottom: 250px;
  background: url(/wp-content/themes/kobaori/img/business/only/bg_01.png) no-repeat right bottom;
  background-size: contain; }
  @media print, screen and (min-width: 768px) {
    .page-only .main .contents__left p {
      padding-right: 44.11765%;
      padding-bottom: 80px; } }
.page-only .main .contents__link {
  padding: 60px 20px;
  background: url(/wp-content/themes/kobaori/img/business/only/bg_02.png) no-repeat center center;
  background-size: cover;
  text-align: center; }
  @media print, screen and (min-width: 768px) {
    .page-only .main .contents__link {
      padding: 110px 0 90px; } }
  .page-only .main .contents__link a {
    display: inline-block; }

.page-package .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/business/package/main_visual_01.png); }
.page-package .main__contents .contents__left img {
  margin-bottom: 1.5em; }

.page-rd .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/business/rd/main_visual_01.png); }
.page-rd .main .contents__left p {
  margin-bottom: 50px; }
.page-rd .main .contents__left li {
  width: 100%;
  padding: 20px 10px;
  text-align: center;
  border: 1px solid #dbdbdb;
  position: relative;
  margin-top: -1px; }

.page-rfid .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/business/rfid/main_visual_01.png); }
.page-rfid .main .box--rfid {
  background: url(/wp-content/themes/kobaori/img/business/rfid/bg_01.png) no-repeat center center;
  background-size: cover;
  padding: 30px;
  text-align: center;
  margin: 40px 0; }
  @media print, screen and (min-width: 768px) {
    .page-rfid .main .box--rfid {
      padding: 87px 0; } }
.page-rfid .main .box--fill .box__contents dt, .page-rfid .main .box--fill .box__title dt {
  font-size: 18px;
  font-size: 1.8rem; }
.page-rfid .main .box--fill .box__contents dd, .page-rfid .main .box--fill .box__title dd {
  margin-bottom: 1em; }
@media print, screen and (min-width: 768px) {
  .page-rfid .main .box--fill .box__contents, .page-rfid .main .box--fill .box__title {
    margin-left: 520px; } }
.page-rfid .main .box--fill .box__title {
  padding: 20px 20px 0; }
.page-rfid .main .box--fill .box__contents {
  padding-top: 0;
  padding-bottom: 0; }

.page-security .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/business/security/main_visual_01.png); }
.page-security .main .contents__one-col .box__contents dt {
  font-size: 18px;
  font-size: 1.8rem; }
.page-security .main .contents__one-col .box__contents dd {
  margin-bottom: 1em; }
@media print, screen and (min-width: 768px) {
  .page-security .main .contents__one-col .box__contents {
    margin-left: 260px; } }
.page-security .main .contents__one-col .clearfix {
  margin-top: 40px; }
  @media print, screen and (min-width: 768px) {
    .page-security .main .contents__one-col .clearfix {
      margin-top: 80px; } }
  .page-security .main .contents__one-col .clearfix .left {
    margin-bottom: 20px; }
    @media print, screen and (min-width: 768px) {
      .page-security .main .contents__one-col .clearfix .left {
        margin-bottom: 0; } }
  @media print, screen and (min-width: 768px) {
    .page-security .main .contents__one-col .clearfix .left, .page-security .main .contents__one-col .clearfix .right {
      width: 48%; } }

.archive .main__visual, .news .main__visual, .single .main__visual, .page-news-information .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/news/common/main_visual_01.png); }
.archive__head, .news__head, .single__head, .page-news-information__head {
  border: 5px solid #d8d9db;
  border-radius: 5px;
  padding: 18px 16px 4px;
  margin-bottom: 40px; }
  .archive__head--list, .news__head--list, .single__head--list, .page-news-information__head--list {
    padding: 7px 16px 10px;
    margin-bottom: 20px; }
.archive__title, .news__title, .single__title, .page-news-information__title {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 25px;
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid #7f7f7f; }
  .archive__title h1, .news__title h1, .single__title h1, .page-news-information__title h1 {
    font-weight: normal;
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 33px;
    padding-bottom: 18px; }
  .archive__title h2, .news__title h2, .single__title h2, .page-news-information__title h2 {
    font-weight: normal;
    padding-bottom: 3px; }
  .archive__title--news, .archive__title--news a, .news__title--news, .news__title--news a, .single__title--news, .single__title--news a, .page-news-information__title--news, .page-news-information__title--news a {
    color: #2356a7; }
  .archive__title--information, .archive__title--inforamtion a, .news__title--information, .news__title--inforamtion a, .single__title--information, .single__title--inforamtion a, .page-news-information__title--information, .page-news-information__title--inforamtion a {
    color: #5c4632; }
.archive__date, .news__date, .single__date, .page-news-information__date {
  text-align: right; }
  .archive__date span, .news__date span, .single__date span, .page-news-information__date span {
    display: inline-block; }
.archive__icon, .news__icon, .single__icon, .page-news-information__icon {
  padding: 0 10px;
  color: #fff; }
  .archive__icon--news, .news__icon--news, .single__icon--news, .page-news-information__icon--news {
    background: #2356a7; }
  .archive__icon--information, .news__icon--information, .single__icon--information, .page-news-information__icon--information {
    background: #5c4632; }
.archive__pagenation, .news__pagenation, .single__pagenation, .page-news-information__pagenation {
  padding: 20px 0;
  position: relative;
  text-align: center; }
  .archive__pagenation span, .archive__pagenation a, .news__pagenation span, .news__pagenation a, .single__pagenation span, .single__pagenation a, .page-news-information__pagenation span, .page-news-information__pagenation a {
    display: inline-block;
    margin-right: 2px;
    padding: 0 5px;
    height: 25px;
    line-height: 25px;
    text-decoration: none;
    border: 1px solid #bfc2c3; }
  .archive__pagenation span.is-current, .news__pagenation span.is-current, .single__pagenation span.is-current, .page-news-information__pagenation span.is-current {
    border: 1px solid #000; }
  .archive__pagenation a:hover, .news__pagenation a:hover, .single__pagenation a:hover, .page-news-information__pagenation a:hover {
    border: 1px solid #000; }
@media print, screen and (min-width: 768px) {
  .archive__body, .news__body, .single__body, .page-news-information__body {
    padding: 0 20px; } }
.archive__body p, .archive__body img, .archive__body ul, .archive__body table, .news__body p, .news__body img, .news__body ul, .news__body table, .single__body p, .single__body img, .single__body ul, .single__body table, .page-news-information__body p, .page-news-information__body img, .page-news-information__body ul, .page-news-information__body table {
  margin-bottom: 25px; }
.archive__body p, .news__body p, .single__body p, .page-news-information__body p {
  line-height: 25px; }
.archive__body a, .news__body a, .single__body a, .page-news-information__body a {
  text-decoration: underline; }
  .archive__body a:hover, .news__body a:hover, .single__body a:hover, .page-news-information__body a:hover {
    text-decoration: none; }

/*ここからOGAWA*/
/*共通*/
.c-both {
  clear: both;
  display: block;
  visibility: hidden; }

.test_b {
  float: right;
  width: 200px; }

.blur {
  color: #2356a7; }

.mbd {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto; }

/*message.html*/
.message {
  min-height: 300px;
  text-align: right;
  padding-top: 30px;
  height: 268px; }

.sm_tx {
  font-size: 12px;
  font-size: 1.2rem; }

/*concept*/
.con_md {
  padding-top: 50px;
  text-align: center;
  padding-bottom: 30px; }

.con_box {
  width: 100%;
  background-color: #06C; }

.con_box_a {
  border: 1px solid #2553A5;
  display: block;
  text-decoration: blink;
  float: left;
  width: 492px;
  margin: 4px; }

.con_box_b {
  border: 1px solid #2553A5;
  display: block;
  text-decoration: blink;
  float: left;
  width: 490px;
  margin: 4px; }

.con_txt {
  float: left;
  padding: 15px; }

.com_img {
  float: left;
  width: 76px;
  border-right-width: 1px;
  border-right-style: dotted;
  border-right-color: #999; }

.con_in_img {
  width: 100%;
  padding-bottom: 15px;
  text-align: center; }

/*profile*/
.prof_box {
  width: 480px;
  float: left;
  margin-right: 10px;
  margin-left: 10px; }

.prof_i_box_out {
  width: 480px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #656565;
  margin-bottom: 18px;
  padding-bottom: 18px; }

.prof_box_l_l {
  float: left;
  width: 130px;
  margin-left: 10px;
  font-weight: bold; }

.prof_box_l_r {
  float: right;
  width: 320px;
  margin-left: 20px; }

.prof_box_r {
  width: 480px;
  float: left;
  border: 1px solid #1e1e1e; }

.prof_box_r_b {
  width: 480px;
  float: left;
  border: 1px solid #1e1e1e;
  margin-top: 30px;
  padding-top: 15px;
  padding-right: 4px;
  padding-bottom: 15px;
  padding-left: 15px;
  margin-bottom: 30px; }

.prof_box_r_under {
  width: 480px;
  float: left;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-color: #1e1e1e;
  border-bottom-color: #1e1e1e;
  border-left-color: #1e1e1e; }

.prof_r_l {
  width: 400px;
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px; }

.prof_r_r {
  height: 150px;
  width: 21px;
  float: right;
  background-color: #2356a7;
  background-image: url(/wp-content/themes/kobaori/img/corp/profile/arrow_s.gif);
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px; }

.prof_r_r_sml {
  height: 35px;
  width: 21px;
  float: right;
  background-color: #2356a7;
  background-image: url(/wp-content/themes/kobaori/img/corp/profile/arrow_s.gif);
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 4px;
  margin-right: 4px; }

.prof_r_r_sml2 {
  height: 50px;
  width: 21px;
  float: right;
  background-color: #2356a7;
  background-image: url(/wp-content/themes/kobaori/img/corp/profile/arrow_s.gif);
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px; }

.prof_r_r a {
  display: block;
  width: 100%;
  height: 100%; }

.prof_box_r dl {
  width: 400px;
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px; }

.prof_box_r dt {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 15px; }

.prof_box_r dd {
  margin-left: 55px; }

.prof_box_r p {
  width: 400px;
  float: left;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 15px; }

.prof_box_r_under p {
  width: 400px;
  float: left;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 15px; }

.md_blur {
  color: #2356a7;
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 15px; }

/*history*/
.his_box {
  background-image: url(/wp-content/themes/kobaori/img/corp/history/ma_1947.png);
  background-repeat: repeat-y;
  padding-left: 39px; }

.his_box_1960 {
  background-image: url(/wp-content/themes/kobaori/img/corp/history/ma_1960.png);
  background-repeat: repeat-y;
  padding-left: 39px; }

.his_box_1970 {
  background-image: url(/wp-content/themes/kobaori/img/corp/history/ma_1970.png);
  background-repeat: repeat-y;
  padding-left: 39px; }

.his_box_1980 {
  background-image: url(/wp-content/themes/kobaori/img/corp/history/ma_1980.png);
  background-repeat: repeat-y;
  padding-left: 39px; }

.his_box_1990 {
  background-image: url(/wp-content/themes/kobaori/img/corp/history/ma_1990.png);
  background-repeat: repeat-y;
  padding-left: 39px; }

.his_box_2000 {
  background-image: url(/wp-content/themes/kobaori/img/corp/history/ma_2000.png);
  background-repeat: repeat-y;
  padding-left: 39px; }

.his_box_2010 {
  background-image: url(/wp-content/themes/kobaori/img/corp/history/ma_2010.png);
  background-repeat: repeat-y;
  padding-left: 39px; }

.his_1947 {
  background-color: #931bad;
  padding: 1px;
  display: inline-block;
  width: 100%;
  position: relative; }

.his_1960 {
  background-color: #8917AA;
  padding: 1px;
  display: inline-block;
  width: 100%;
  position: relative; }

.his_1970 {
  background-color: #6D26A9;
  padding: 1px;
  display: inline-block;
  width: 100%;
  position: relative; }

.his_1980 {
  background-color: #4B3CA6;
  padding: 1px;
  display: inline-block;
  width: 100%;
  position: relative; }

.his_1990 {
  background-color: #3D43A5;
  padding: 1px;
  display: inline-block;
  width: 100%;
  position: relative; }

.his_2000 {
  background-color: #2E4EA5;
  padding: 1px;
  display: inline-block;
  width: 100%;
  position: relative; }

.his_2010 {
  background-color: #2E4EA5;
  padding: 1px;
  display: inline-block;
  width: 100%;
  position: relative; }

.his_b {
  float: left;
  width: 25%;
  position: absolute;
  top: 50%;
  margin-top: -15px; }

.his_txt {
  background-color: #FFF;
  float: right;
  width: 75%; }

.his_txt p {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: #999;
  padding-left: 20px; }

.his_c_1947 {
  color: #9202AA; }

.his_c_1960 {
  color: #8917AA; }

.his_c_1970 {
  color: #6D26A9; }

.his_c_1980 {
  color: #4B3CA6; }

.his_c_1990 {
  color: #9202AA; }

.his_c_2000 {
  color: #2E4EA5; }

.his_c_2010 {
  color: #2E4EA5; }

.his_ma {
  height: 15px; }

.his_ma {
  height: 10px; }

.his_in_box {
  padding-left: 130px; }

.his_in_box_y {
  float: left; }

.his_in {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: #999;
  padding-left: 20px; }

.crs_box_a {
  border: 1px solid #237da7;
  display: block;
  text-decoration: blink;
  float: left;
  width: 492px;
  margin: 4px; }

/*CRS*/
.crs_box_b {
  border: 1px solid #237da7;
  display: block;
  text-decoration: blink;
  margin: 4px;
  min-width: 492px;
  position: relative; }

.crs_box_c {
  border: 1px solid #249CA3;
  display: block;
  text-decoration: blink;
  float: left;
  width: 492px;
  margin: 4px; }

.crs_box_d {
  border: 1px solid #249CA3;
  display: block;
  text-decoration: blink;
  margin: 4px;
  min-width: 492px;
  position: relative; }

.crs_txt {
  float: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #297CA6;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 90px; }

.crs_b_txt_in p {
  text-decoration: none;
  border-left-width: 5px;
  border-left-style: solid;
  border-left-color: #237da7;
  padding-left: 20px;
  font-size: 18px;
  font-size: 1.8rem;
  color: #237da7;
  margin-bottom: 5px;
  margin-top: 10px; }

.crs_b_txt_in_end p {
  text-decoration: none;
  border-left-width: 5px;
  border-left-style: solid;
  border-left-color: #237da7;
  padding-left: 20px;
  font-size: 18px;
  font-size: 1.8rem;
  color: #237da7;
  margin-bottom: 5px;
  margin-top: 10px; }

.crs_box_d .crs_d_txt_in p {
  text-decoration: none;
  border-left-width: 5px;
  border-left-style: solid;
  border-left-color: #249CA3;
  padding-left: 20px;
  font-size: 18px;
  font-size: 1.8rem;
  color: #249CA3;
  margin-bottom: 5px;
  margin-top: 10px; }

.crs_box_d .crs_d_txt_in_end p {
  text-decoration: none;
  border-left-width: 5px;
  border-left-style: solid;
  border-left-color: #249CA3;
  padding-left: 20px;
  font-size: 18px;
  font-size: 1.8rem;
  color: #249CA3;
  margin-bottom: 5px;
  margin-top: 10px; }

.crs_line {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #237da7;
  padding-bottom: 50px;
  width: 100px; }

.crs_txt_in {
  padding-left: 25px; }

.crs_b_txt {
  float: none;
  padding: 12px;
  border-left-width: 1px;
  border-left-style: dotted;
  border-left-color: #999;
  margin-left: 80px; }

.crs_b_txt_in {
  border-bottom-style: solid;
  border-bottom-color: #297CA6;
  padding-bottom: 12px;
  width: 100%;
  border-bottom-width: 1px; }

.crs_d_txt_in {
  border-bottom-style: solid;
  border-bottom-color: #249CA3;
  padding-bottom: 12px;
  width: 100%;
  border-bottom-width: 1px; }

.crs_b_txt_in_end {
  padding-bottom: 12px;
  width: 100%; }

.crs_d_txt_in_end {
  padding-bottom: 12px;
  width: 100%; }

.crs_img_no {
  float: left;
  width: 75px;
  position: absolute;
  top: 50%;
  margin-top: -65px; }

.crs_setumei {
  padding-left: 25px; }

/*network*/
.net_box {
  border: 4px solid #2356a7;
  margin-top: 30px; }

.net_box_japan {
  border: 4px solid #2356a7;
  margin-top: 30px;
  padding: 5px;
  min-width: 500px; }

.net_box .map {
  background-image: url(/wp-content/themes/kobaori/img/corp/network/map.png);
  background-repeat: no-repeat;
  height: 778px;
  width: 982px; }

.net_box_japan .japan_main {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #d8d8d8;
  margin-right: 20px;
  margin-left: 20px; }

.net_j_add {
  width: 451px;
  float: left;
  margin: 20px; }

.net_j_add h1 {
  text-decoration: none;
  font-size: 22px;
  font-size: 2.2rem; }

.net_j_add h2 {
  text-decoration: none;
  font-size: 18px;
  font-size: 1.8rem; }

.net_j_add p {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: #999; }

.ggmap {
  width: 471px;
  float: left;
  position: relative;
  padding-bottom: 250px;
  height: 0;
  overflow: hidden; }

.ggmap iframe,
.ggmap object,
.ggmap embed {
  position: absolute;
  width: 100%;
  height: 210px;
  margin: 20px; }

.net_j_add .line {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: #999;
  width: 450px; }

.net_j_add .line_end {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #d8d8d8;
  width: 450px; }

.net_menu {
  width: 100%; }

.net_menu ul li {
  display: inline;
  float: left;
  padding: 0;
  margin: 0;
  width: 33.3333333%;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #444; }

.net_menu ul li a {
  display: block; }

.mbd .net_box_japan div .net_j_add img {
  float: right; }

.net_j_add_m {
  width: 451px;
  margin: 20px; }

.net_j_add_m h2 {
  text-decoration: none;
  font-size: 18px;
  font-size: 1.8rem; }

.net_j_add_m .line_end {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #d8d8d8;
  width: 450px; }

.mbd .net_box_japan div .net_j_add_m img {
  float: right; }

.netbox50 {
  float: left;
  width: 491px; }

.mbd .net_box_japan .net_j_add img {
  float: right; }

.net_wd_line {
  margin-top: 25px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-left: 20px;
  padding-bottom: 20px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #d8d8d8;
  min-width: 450px; }

.net_wd_line_end {
  margin-top: 25px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-left: 20px;
  padding-bottom: 20px;
  min-width: 450px; }

.net_wd_l {
  float: left;
  width: 63%; }

.net_wd_r {
  float: right;
  width: 35%; }

.net_wd_l h1 {
  text-decoration: none;
  font-size: 22px;
  font-size: 2.2rem;
  margin-bottom: 15px; }

.line {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: #999; }

.line_end {
  padding-top: 10px; }

.net_wd_l .net_wd_info {
  float: left;
  width: 400px; }

.net_wd_l .wd_ph {
  float: left;
  width: 155px;
  margin-right: 20px; }

.net_wd_l a img {
  float: right; }

.net_wd_r .wd_map {
  text-align: right;
  margin-bottom: 15px; }

.net_menu {
  background-image: url(/wp-content/themes/kobaori/img/corp/network/sub_menu_01_on.png);
  height: 56px;
  width: 333px;
  background-repeat: no-repeat; }

.net_menu img {
  border: none; }

.net_menu a:hover {
  background: none; }

.net_menu a:hover img {
  visibility: hidden; }

.network_menu a img {
  float: left;
  max-width: 33.3333%;
  height: auto; }

.map .kuwake {
  width: 980px;
  padding-top: 145px; }

.map .china {
  height: 212px;
  margin-left: 187px;
  width: 265px; }

.map .china img {
  margin-bottom: 8px; }

.map .t_j_box {
  padding-top: 40px;
  width: 100%; }

.t_j_box .net_thai {
  width: 291px;
  height: 27px;
  float: left;
  margin-top: 85px;
  margin-left: 38px; }

.t_j_box .net_jp {
  float: left;
  height: 138px;
  width: 198px;
  margin-left: 432px; }

.net_jp .jp_l {
  width: 198px;
  margin-bottom: 14px;
  height: 27px; }

.net_jp .jp_l img {
  margin-right: 4px; }

.net_jp .jp_l_2 {
  width: 198px;
  margin-bottom: 4px;
  height: 27px; }

.kuwake .ind {
  width: 180px;
  margin-top: 10px;
  height: 27px;
  margin-left: 220px; }

.net_jp .jp_l_2 img {
  margin-right: 4px; }

.net_jp .jp_l_3 {
  width: 155px;
  margin-bottom: 4px;
  height: 27px;
  margin-left: 43px; }

.net_jp .jp_l_3 img {
  margin-right: 4px; }

/*recruit*/
.rec_box {
  max-width: 1000px;
  border: 5px solid #d8d9db;
  text-align: center;
  padding-top: 100px;
  padding-right: 50px;
  padding-bottom: 100px;
  padding-left: 50px; }

.privacy {
  max-width: 1000px; }

.privacy p {
  color: #2356a7;
  margin-top: 15px;
  margin-bottom: 0px; }

.privacy table {
  width: 100%;
  table-layout: fixed; }

.privacy table th,
.privacy table td {
  border: 1px solid #ccc;
  padding: 5px 10px; }

/*contact*/
.contact_md {
  text-decoration: none;
  font-size: 22px;
  font-size: 2.2rem;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: #999;
  padding-bottom: 5px;
  margin-bottom: 10px; }

.contact_mmd {
  font-size: 18px;
  font-size: 1.8rem; }

.contact_required {
  color: #dd0000; }

.contact_formbox {
  max-width: 900px;
  border: 1px solid #3f3f3f;
  margin-right: auto;
  margin-left: auto;
  background-image: url(/wp-content/themes/kobaori/img/contact/form_bg.png);
  background-repeat: repeat-y; }

.contact_yoko {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #cecece; }

.contact_item {
  padding: 10px;
  width: 180px;
  float: left; }

.contact_entry {
  padding: 10px;
  float: left;
  background-color: #FFF; }

/*.contact_yoko {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: #cecece;
	height: 41px;
}
.contact_item {
	padding: 10px;
	background-color: #f1f1f1;
	width: 180px;
	border-right-width: 1px;
	border-right-style: solid;
	border-right-color: #cecece;
	height: 40px;
	float: left;
}
.contact_entry {
	padding: 10px;
	height: 40px;
	float: left;
}
*/
.contact_submit {
  padding: 20px;
  text-align: center;
  width: 250px;
  margin-right: auto;
  margin-left: auto; }

/*メインビジュアル設定*/
.page-contact .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/contact/main_visual_01.jpg); }

.page-site-policy .main__visual,
.page-privacy-policy .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/privacy/main_visual_01.jpg); }

.page-recruit .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/recruit/main_visual_01.jpg); }

.page-japan .main__visual, .page-world .main__visual {
  background-image: url(/wp-content/themes/kobaori/img/corp/common/main_visual_01.jpg); }

#btn-pagetop {
  clear: both;
  display: none;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: fixed;
  right: 15px;
  bottom: 15px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  z-index: 999;
  background: #000 url(/wp-content/themes/kobaori/img/common/bg_pagetop_01.png) no-repeat center center; }
  @media print, screen and (min-width: 768px) {
    #btn-pagetop {
      display: inline-block; } }

.box {
  position: relative;
  padding: 30px; }
  .box__title {
    text-align: center; }
    .box__title span {
      display: inline-block;
      padding-bottom: 14px;
      margin-bottom: 17px;
      border-bottom: 1px solid #cecece; }
    .box__title--blue {
      position: absolute;
      width: 100%;
      text-align: center;
      top: -11px;
      left: 0; }
      .box__title--blue span {
        padding: 0 15px;
        background: #fff;
        margin: 0;
        border: none; }
  .box--border {
    border: 1px solid #cecece; }
  .box--border-blue {
    border: 1px solid #2356a7; }
  .box--fill {
    padding: 0;
    overflow: hidden; }
    .box--fill > .box__img {
      text-align: center;
      padding: 10px; }
      @media print, screen and (min-width: 768px) {
        .box--fill > .box__img {
          float: left;
          padding: 0; } }
    .box--fill .box__contents, .box--fill .box__title {
      padding: 15px; }
      @media print, screen and (min-width: 768px) {
        .box--fill .box__contents, .box--fill .box__title {
          padding: 30px; } }

.left {
  float: left; }

.right {
  float: right; }

.clear {
  clear: both; }

.clearfix {
  overflow: hidden; }

.pie-clearfix:before, .pie-clearfix:after {
  content: "";
  display: table; }
.pie-clearfix:after {
  clear: both; }

.is-hide {
  display: none; }

.is-invisible {
  opacity: 0;
  filter: alpha(opacity=0); }

.is-visible {
  opacity: 1;
  filter: alpha(opacity=100); }

.smp-hide {
  display: none; }

@media print, screen and (min-width: 768px) {
  .smp-show {
    display: none; }

  .smp-hide {
    display: block; } }
.inner {
  padding: 0 10px;
  box-sizing: border-box;
  max-width: 100%; }
  @media print, screen and (min-width: 768px) {
    .inner {
      padding: 0;
      width: 1100px;
      margin: 0 auto; } }

.mt0 {
  margin-top: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mt5 {
  margin-top: 5px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.mt35 {
  margin-top: 35px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.mt40 {
  margin-top: 40px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.mt45 {
  margin-top: 45px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.mt50 {
  margin-top: 50px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

.mt55 {
  margin-top: 55px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.mr55 {
  margin-right: 55px !important; }

.mt60 {
  margin-top: 60px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.mr60 {
  margin-right: 60px !important; }

.mt65 {
  margin-top: 65px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.mt70 {
  margin-top: 70px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.ml70 {
  margin-left: 70px !important; }

.mr70 {
  margin-right: 70px !important; }

.mt75 {
  margin-top: 75px !important; }

.mb75 {
  margin-bottom: 75px !important; }

.ml75 {
  margin-left: 75px !important; }

.mr75 {
  margin-right: 75px !important; }

.mt80 {
  margin-top: 80px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.ml80 {
  margin-left: 80px !important; }

.mr80 {
  margin-right: 80px !important; }

.mt85 {
  margin-top: 85px !important; }

.mb85 {
  margin-bottom: 85px !important; }

.ml85 {
  margin-left: 85px !important; }

.mr85 {
  margin-right: 85px !important; }

.mt90 {
  margin-top: 90px !important; }

.mb90 {
  margin-bottom: 90px !important; }

.ml90 {
  margin-left: 90px !important; }

.mr90 {
  margin-right: 90px !important; }

.mt95 {
  margin-top: 95px !important; }

.mb95 {
  margin-bottom: 95px !important; }

.ml95 {
  margin-left: 95px !important; }

.mr95 {
  margin-right: 95px !important; }

.mt100 {
  margin-top: 100px !important; }

.mb100 {
  margin-bottom: 100px !important; }

.ml100 {
  margin-left: 100px !important; }

.mr100 {
  margin-right: 100px !important; }

.pt0 {
  padding-top: 0px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.pl0 {
  padding-left: 0px !important; }

.pr0 {
  padding-right: 0px !important; }

.pt5 {
  padding-top: 5px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pt10 {
  padding-top: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pt25 {
  padding-top: 25px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pt30 {
  padding-top: 30px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pt35 {
  padding-top: 35px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pt40 {
  padding-top: 40px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pt45 {
  padding-top: 45px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pt50 {
  padding-top: 50px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pt55 {
  padding-top: 55px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pt60 {
  padding-top: 60px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pt65 {
  padding-top: 65px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pt70 {
  padding-top: 70px !important; }

.pb70 {
  padding-bottom: 70px !important; }

.pl70 {
  padding-left: 70px !important; }

.pr70 {
  padding-right: 70px !important; }

.pt75 {
  padding-top: 75px !important; }

.pb75 {
  padding-bottom: 75px !important; }

.pl75 {
  padding-left: 75px !important; }

.pr75 {
  padding-right: 75px !important; }

.pt80 {
  padding-top: 80px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.pl80 {
  padding-left: 80px !important; }

.pr80 {
  padding-right: 80px !important; }

.pt85 {
  padding-top: 85px !important; }

.pb85 {
  padding-bottom: 85px !important; }

.pl85 {
  padding-left: 85px !important; }

.pr85 {
  padding-right: 85px !important; }

.pt90 {
  padding-top: 90px !important; }

.pb90 {
  padding-bottom: 90px !important; }

.pl90 {
  padding-left: 90px !important; }

.pr90 {
  padding-right: 90px !important; }

.pt95 {
  padding-top: 95px !important; }

.pb95 {
  padding-bottom: 95px !important; }

.pl95 {
  padding-left: 95px !important; }

.pr95 {
  padding-right: 95px !important; }

.pt100 {
  padding-top: 100px !important; }

.pb100 {
  padding-bottom: 100px !important; }

.pl100 {
  padding-left: 100px !important; }

.pr100 {
  padding-right: 100px !important; }

.is-absolute {
  position: absolute; }

.is-fixed {
  position: fixed; }

.is-relative {
  position: relative; }

.t-left {
  text-align: left; }

.t-right {
  text-align: right; }

.t-center {
  text-align: center; }

.is−bold {
  font-weight: bold; }

.is-hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

.aligncenter {
  display: block;
  margin: 0 auto; }

.alignright {
  float: right; }

.alignleft {
  float: left; }

img[class*="wp-image-"],
img[class*="attachment-"] {
  height: auto;
  max-width: 100%; }

.wp-caption {
  border: 1px solid #ddd;
  text-align: center;
  background-color: #f3f3f3;
  padding-top: 4px;
  margin: 10px;
  /* optional rounded corners for browsers that support it */
  -moz-border-radius: 3px;
  -khtml-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.wp-caption img {
  margin: 0;
  padding: 0;
  border: 0 none; }

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  padding: 0 4px 5px;
  margin: 0; }


